<template>
  <v-content class="organizations-view">
    <v-row
      align="center"
      justify="center"
      class="company-container fill-height"
    >
      <v-col
        cols="12"
        md="5"
        lg="4"
        xl="3"
      >
        <v-img
          :src="require('@/assets/img/logo_full.png')"
          width="200"
          class="company-container__logo"
        />
        <v-card class="company-container__card">
          <v-card-title
            primary-title
            class="pl-6"
          >
            <h3>{{ $t('App.company.pageTitle') }}</h3>
          </v-card-title>
          <v-card-actions>
            <v-list class="company-container__card__list px-4">
              <v-list-item
                v-for="company in companies"
                :key="company.name"
                class="company-container__card__list-tile elevation-1"
                @click="setActiveCompany(company)"
              >
                <v-list-item-content>
                  {{ company.name }}
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-content>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'Organization',
  props: {
    hideLayout: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('company', [
      'companies',
      'activeCompany'
    ]),
    shallUseNpRed() {
      if (!(this && this.$router && this.$router.options)) {
        return false;
      }
      const availableRoutes = this.$router.options.routes;
      const filterRule = route => route.path === this.npRedParam ||
        (route.children && route.children.filter(child => child.path === this.npRedParam));

      return this.npRedParam && availableRoutes.filter(filterRule).length;
    },
    npRedParam() {
      if (!(this && this.$route)) {
        return '';
      }
      return this.$route.params.np_red;
    }
  },
  watch: {
    activeCompany: {
      deep: true,
      handler() {
        this.$nextTick(() => {
          if (this.activeCompany && this.activeCompany.hasOwnProperty('id')) {
            this.redirectUser();
          }
        });
      }
    }
  },
  mounted() {
    if (this.activeCompany && this.activeCompany.hasOwnProperty('id')) {
      this.redirectUser();
    }
  },
  methods: {
    ...mapActions('company', [
      'setActiveCompany'
    ]),
    redirectUser() {
      let redirectTo = '/notificacoes';

      if (this.shallUseNpRed) {
        redirectTo = this.npRedParam;
      }
      this.$router.replace({
        path: redirectTo,
        params: {
          companyId: String(this.activeCompany.id)
        }
      }).catch(/*istanbul ignore next*/() => {});
    }
  }
};
</script>

<style scoped lang="scss">
  .organizations-view {
    background-color: var(--gray-050);
  }

  .company-container__logo {
    margin: -50% auto 20px;
  }

  .company-container__card * {
    background-color: var(--gray-100);
    color: var(--white);
  }

  .company-container__card__list {
    padding-top: 0;
    width: 100%;
  }

  ::v-deep {
    .company-container__card__list-tile {
      border-radius: 3px;
      width: 100%;
      height: 51px;
      margin-bottom: 5px;
      transition: none;

      .v-list__tile--link {
        transition: none !important;
      }

      &:hover,
      &:hover * {
        background-color: var(--gray-050) !important;
        color: var(--white);
      }
    }
  }
</style>
