var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-content",
    { staticClass: "organizations-view" },
    [
      _c(
        "v-row",
        {
          staticClass: "company-container fill-height",
          attrs: { align: "center", justify: "center" }
        },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "5", lg: "4", xl: "3" } },
            [
              _c("v-img", {
                staticClass: "company-container__logo",
                attrs: {
                  src: require("@/assets/img/logo_full.png"),
                  width: "200"
                }
              }),
              _c(
                "v-card",
                { staticClass: "company-container__card" },
                [
                  _c(
                    "v-card-title",
                    { staticClass: "pl-6", attrs: { "primary-title": "" } },
                    [
                      _c("h3", [
                        _vm._v(_vm._s(_vm.$t("App.company.pageTitle")))
                      ])
                    ]
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-list",
                        { staticClass: "company-container__card__list px-4" },
                        _vm._l(_vm.companies, function(company) {
                          return _c(
                            "v-list-item",
                            {
                              key: company.name,
                              staticClass:
                                "company-container__card__list-tile elevation-1",
                              on: {
                                click: function($event) {
                                  return _vm.setActiveCompany(company)
                                }
                              }
                            },
                            [
                              _c("v-list-item-content", [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(company.name) +
                                    "\n              "
                                )
                              ])
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }